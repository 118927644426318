<template>
  <div>
    <div
      class="row"
      v-if="$can({ key: 'clients', expectedPermission: 'edit' })"
    >
      <div class="col-12">
        <router-link
          :to="{
            name: 'tasks.add',
            query: { taskType: '7', userId: patients.patient.user_id },
          }"
          class="btn btn-outline-blue mb-3"
        >
          <icon type="plus" /> Add Note
        </router-link>
      </div>
    </div>

    <timeline :userId="patients.patient.user_id" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import timeline from "@/components/timeline.vue";

export default {
  name: "timeline-patient",
  components: {
    timeline,
  },
  computed: {
    ...mapState(["patients"]),
  },
  mounted() {},
  data() {
    return {};
  },
};
</script>
